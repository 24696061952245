<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <!-- 新建链接 -->
      <div class="module_item" v-if="addType==2">
        <div class="title">新建链接</div>
        <div class="form_box">
          <el-form-item class="form_item" label="内容标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="链接地址：" prop="content">
            <el-input v-model="formData.content"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
            v-model="formData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
          </el-form-item>
        </div>
      </div>
      <!-- 新建图文 -->
      <div class="module_item" v-if="addType==1">
        <div class="title">新建图文</div>
        <div class="form_box">
          <el-form-item class="form_item" label="内容标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="内容：" :rules="{ required: true, message: `请输入内容`, trigger: 'blur' }" style="width: 100%;">
            <custom-editor :option="option" v-model="formData.content"></custom-editor>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
            v-model="formData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
          </el-form-item>
        </div>
      </div>
      <!-- 新建视频 -->
      <div class="module_item" v-if="addType==3">
        <div class="title">新建视频</div>
        <div class="form_box">
          <el-form-item class="form_item" label="内容标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item label="上传内容：" :rules="{ required: true, message: `请上传视频`, trigger: 'change' }" class="form_item">
            <file-upload
              v-model="formData.content"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              fileType="video"
              :defaultFileList="contentVideoList"
              :maxNum="1"
              width="120px"
              height="120px"
              @uploadSuccess="handleVideoUploadSuccess"
            ></file-upload>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
            v-model="formData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
          </el-form-item>
        </div>
      </div>
      <!-- 新建路由 -->
      <div class="module_item" v-if="addType==4">
        <div class="title">新建页面</div>
        <div class="form_box">
          <el-form-item class="form_item" label="内容标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="页面路由：" prop="content">
            <el-input v-model="formData.content"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
            v-model="formData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
          </el-form-item>
        </div>
      </div>

      <div class="module_item" v-if="addType==5">
        <div class="title">新建微信小程序跳转链接</div>
        <div class="form_box">
          <el-form-item class="form_item" label="内容标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="跳转地址：" prop="content">
            <el-input v-model="formData.content"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="状态：" prop="status">
            <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </el-form-item>
        </div>
      </div>

    </el-form>
    <div class="form_btn" v-if="pageName!=='contentModuleDetail'">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

// 售卖价格验证方法
const checkIsPositive =(rule, value, callback)=> {
  if(!value){
    callback()
  } else {
    const reg = /^\d+(?=\.{0,1}\d+$|$)/
    if (reg.test(value)){
      callback()
    } else {
      callback(new Error('金额需大于等于0'))
    }
  }
}
export default {
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isAdd: null, //是否为添加
        addType: '',                                //新建类型 2-新建链接 1-新建图文 3-新建视频
        formData: {
          title: '',
          content: '',
          status: 1
        },
        formRules: {
          title: { required: true, message: '请输入内容标题', trigger: 'change' },
          content: { required: true, message: '请输入链接地址', trigger: 'change' },
        },
        option: {
          height: 500
        },
        html: '<p></p>',
        contentVideoList: []
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "内容管理", path: "/management/content/contentModule/list" },
        {
          name: this.isAdd ? "新建内容" :"修改内容"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      switch (this.tabName) {
        case '1':           //活动参与明细
          this.getAnalysisDetailList(params, num ? num : params.pageNum, params.pageSize);
          break;
        case '2':           //渠道明细
          this.getAnalysisChannelList(params, num ? num : params.pageNum, params.pageSize);
          break;
        case '3':           //用户裂变明细
          this.getAnalysisShareList(params, num ? num : params.pageNum, params.pageSize);
          break;

        default:
          break;
      }
    },
    
    // 视频上传
    handleVideoUploadSuccess(url) {
      console.log(url)
      this.formData.content = url;
      this.$refs.form.validateField("content");
    },
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          let path = ''
          if(this.isAdd){
            path = 'contentModuleAdd'
          }else{
            path = 'contentModuleEdit'
          }
          this.$store.dispatch(`management/${path}`,{
            ...this.formData,
            type: this.addType,
            contentNo: this.$route.query.contentNo||''
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
          })      
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    getDetail(contentNo){
      this.$store.dispatch('management/contentModuleDetail',{
        contentNo
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key];
        })
        this.addType = res.data.type;
        this.formData.id = res.data.id;
        if(res.data.type==3){
          this.contentVideoList.push({url: res.data.content})
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 新建类型
    this.addType = this.$route.query.addType;
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "contentModuleAdd";
    this.pageName = this.$route.name;
    console.log('asdasd',this.isAdd)
    if (this.$route.query.contentNo) {
      this.getDetail(this.$route.query.contentNo);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        align-items: center;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
</style>
